import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, Classes, InputGroup, Intent } from '@blueprintjs/core';
import { Edit, Duplicate } from "@blueprintjs/icons";

export const TemplateName = observer(({ store }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [templateName, setTemplateName] = useState(store.template?.name || '');
    const [charCount, setCharCount] = useState(store.template?.name.length || 0);

    const handleRename = () => {
        setTemplateName(store.template?.name || '');
        setCharCount(store.template?.name.length || 0);
        setIsDialogOpen(true);
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        if (value.length <= 60) {
            setTemplateName(value);
            setCharCount(value.length);
        }
    };

    const handleDialogClose = (isConfirmed) => {
        if (isConfirmed) {
            if (templateName && templateName !== '' && templateName.length <= 60) {
                store.template.name = templateName;
                store.contentChanged = true;
            } else {
                alert("Template name cannot be empty or exceed 60 characters.");
            }
        } else {
            setTemplateName(store.template?.name || '');
        }
        setIsDialogOpen(false);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(store.template?.id);
    };

    return (
        <div className='flex flex-col min-w-[240px]'>
            <div className='font-bold text-[18px] flex items-center' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <span>{store.template?.name || 'New Template'}</span>
                {store.userRole === 'admin' && (
                    <Edit
                        onClick={handleRename}
                        className='ml-2 opacity-50 hover:opacity-100 cursor-pointer'
                        color='#777'
                        size={16}
                    />
                )}
            </div>
            {store.template?.id && (
                <div className='text-[12px] text-gray-500 flex items-center' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <span>{store.template?.id}</span>
                    <Duplicate
                        onClick={handleCopy}
                        className='ml-2 opacity-50 hover:opacity-100 cursor-pointer'
                        color='#333'
                        size={12}
                    />
                </div>
            )}
            <Dialog
                isOpen={isDialogOpen}
                onClose={() => handleDialogClose(false)}
                title="Rename Template"
            >
                <div className={Classes.DIALOG_BODY}>
                    <InputGroup
                        value={templateName}
                        onChange={handleNameChange}
                        maxLength={60}
                        placeholder="Enter new template name"
                    />
                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span>{charCount}/60</span>
                        {charCount === 60 && <span style={{ color: 'red' }}>You have reached the maximum number of characters.</span>}
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => handleDialogClose(false)}>Cancel</Button>
                        <Button intent="primary" onClick={() => handleDialogClose(true)}>OK</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
});









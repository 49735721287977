import { useEffect, useState, useRef } from "react";
import { createStore } from 'polotno/model/store';
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from 'polotno';
import { Toolbar } from 'polotno/toolbar/toolbar';
import { ZoomButtons } from 'polotno/toolbar/zoom-buttons';
import { SidePanel } from 'polotno/side-panel';
import { Workspace } from 'polotno/canvas/workspace';
import { Button, Divider } from '@blueprintjs/core';
import { Cross } from "@blueprintjs/icons";
import { globalStore} from "./components/globalStore";
import { TemplatesPanel } from "./components/templates";
import { AssetsSection } from "./components/assets";
import { TemplateName } from "./components/templateName";
import { TemplateSaveButton } from "./components/templateSaveButton";
import TemplateDownloadButton from "./components/templateDownloadButton";
import fontsFunctions from "./fontsFunctions";

import { TextSection, ElementsSection, TemplatesSection, LayersSection, SizeSection } from 'polotno/side-panel';

import '@blueprintjs/core/lib/css/blueprint.css';

const IMAGE_API_URL = import.meta.env.VITE_IMAGE_API_URL;

const Designer = ({apiKey, template, onSaveImage, onClose}) => {
    globalStore.serverUrl = import.meta.env.VITE_TE_API_URL;
    globalStore.apiKey = apiKey;
    // Determine the user role based on apiKey and session storage role info
    const sessionRole = sessionStorage.getItem('userRole');
    if (apiKey) {
        globalStore.userRole = 'user';
    } else {
        if (sessionRole === 'admin' || sessionRole === 'manager') {
            globalStore.userRole = 'admin';
        } else if (sessionRole === 'editor') {
            globalStore.userRole = 'user';
        } else {
            globalStore.userRole = 'user'; // default role if none is set
        }
    }
    globalStore.contentChanged = false;
    globalStore.template = {
        name : 'New Template'
    }
    const store = createStore();
    store.setRole(globalStore.userRole); // will only work with out api key, but token must be present
    store.addPage();

    const loadTemplate = async() => {
        let req = `${globalStore.serverUrl}/cloudtemplates/${template}`;

        if(template.toLowerCase().indexOf(IMAGE_API_URL)===0){
            req = template.toLowerCase().replace('/image/','/data/');
        }

        const res = await fetch(req, {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            }
        });

        const json = await res.json();
        store.loadJSON(json.content);
        globalStore.template = json;
        globalStore.contentChanged = false;
    }
    // const saveTemplate = async () =>{
    //     const data = {
    //         ...globalStore.template,
    //         content : store.toJSON()
    //     };

    //     const res = await fetch(`${globalStore.serverUrl}/cloudtemplates`, {
    //         method: "POST",
    //         headers: {
    //         'Content-Type': 'application/json',
    //         ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
    //         },
    //         body : JSON.stringify(data)
    //     });

    //     if(res.ok){
    //         globalStore.contentChanged = false;
    //     }
    // };

    const saveImage = async () => {
        const data = await store.toDataURL({ mimeType: 'image/jpg' });
        onSaveImage(data);
    };
    

    const handleClose = () => {
        if(globalStore.contentChanged && !window.confirm("All changes will be lost! Are you sure?")) return;
        onClose();
    }

    const CustomControls = ({ store}) => {
        return (
            <div>
                <TemplateDownloadButton store={store}/>
                {globalStore.userRole === 'admin' && <TemplateSaveButton store={store} />}
            </div>
        );
    };

    TemplatesSection.Panel = TemplatesPanel;
    const sections = [
        AssetsSection,
        TextSection,
        ElementsSection,

    ];

    if(!template) {
        sections.splice(0,0,TemplatesSection);
    }

    if(globalStore.userRole==='admin'){
        sections.push(SizeSection);
    }

    useEffect(()=>{
        const unsubscribeKey = store.on('change', (updatedStore)=>{
            fontsFunctions.manageFonts(updatedStore.fonts);
            globalStore.contentChanged = true
        });
        if(template){
            loadTemplate();
        }
        fontsFunctions.setFontsGlobally().then((fonts) => {
            fonts.forEach(font => {
                store.addFont(font);
            });
            globalStore.fontsInProgress = false;
        })
        return unsubscribeKey;
    },[]);

  return (

        <PolotnoContainer className="flex flex-col bg-white ">
            {onSaveImage && <div className="py-2 pl-1 pr-5 flex items-center justify-between border border-t-gray-300 bg-gray-100">
                <a href="https://www.templatefarm.io" target="_blank" rel="noreferrer" className="outline-none">
                    <img className="h-8" src='https://d2f9eheulyqk7v.cloudfront.net/internal/te-logo.svg' alt='TELogo'/>
                </a>
                <div className="flex">
                    <Cross className="opacity-50 hover:opacity-100 cursor-pointer" onClick={handleClose}/>
                </div>
            </div>}
            <div className="flex border border-b-gray-200">
                <div className="flex items-center w-auto bp5-navbar">
                    <TemplateName store={globalStore}/>
                </div>
                <Toolbar store={store} components={{ActionControls: CustomControls}}/>
            </div>
            <div className="flex flex-auto overflow-y-hidden">
                <SidePanelWrap>
                    <SidePanel store={store} sections={sections} defaultSection="templates" />
                </SidePanelWrap>
                <WorkspaceWrap>

                    <Workspace store={store} />
                    <ZoomButtons store={store} />
                </WorkspaceWrap>
                <div className="p-1 min-w-80">
                    <LayersSection.Panel store={store} />
                </div>
            </div>
            {onSaveImage && <div className="py-2 px-3 flex justify-between border border-t-gray-300 bg-gray-100">
                <div className="flex">
                </div>
                <div className="flex">
                    <Button intent="primary" icon="circle-arrow-down" onClick={saveImage}>Save</Button>
                </div>

            </div>}
        </PolotnoContainer>

  );
}

export default Designer

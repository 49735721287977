import dataURLtoBlob from "./helperFunctions";
import { globalStore} from "./components/globalStore";

const uploadFont = async (fontToUpload) => {
    const formData = new FormData();
    const blob = dataURLtoBlob(fontToUpload.url);
    formData.append('file', blob, fontToUpload.fontFamily || "");
    
    await fetch(`${globalStore.serverUrl}/cloudassets/font`, {
        method: 'POST',
        headers: {
            ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
        },
        body: formData,
    });
    globalStore.fonts.push(fontToUpload);
}

const deleteFont = async (fontToDelete) => {
    await fetch(`${globalStore.serverUrl}/cloudassets/font/${fontToDelete.fontFamily}`, {
        method: 'DELETE',
        headers: {
          ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
        }
    });
    globalStore.fonts = globalStore.fonts.filter(font => font.fontFamily !== fontToDelete.fontFamily);
}

const manageFonts = (fonts) => {
    if(globalStore.fontsInProgress) {
        return;
    }
    if(globalStore.fonts.length < fonts.length) {
        uploadFont(fonts[fonts.length - 1]);
    } else if (globalStore.fonts.length > fonts.length) {
        const fontToDelete = globalStore.fonts.find(globalFont => !fonts.find(font => font.fontFamily === globalFont.fontFamily));
        deleteFont(fontToDelete);
    }
}

const setFontsGlobally = async () => {
    try {
        const queryParams = new URLSearchParams();
        queryParams.append('type', 'font');
        
        const response = await fetch(`${globalStore.serverUrl}/cloudassets/${''}?${queryParams.toString()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
            }
        });
        
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        const fonts = data.assets.map(asset => {
            return {
                fontFamily: asset.name,
                url: asset.url
            }  
        });
        globalStore.fonts = fonts;
        globalStore.fontsInProgress = true;
        return fonts;
                
    } catch (error) {
        console.error('Error loading fonts:', error);
    }
}


export default {
    manageFonts,
    setFontsGlobally,
};
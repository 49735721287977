import React from 'react';
import { observer } from 'mobx-react-lite';
import { InputGroup } from '@blueprintjs/core';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { globalStore} from './globalStore';
import { Button } from '@blueprintjs/core';
import { Thumbnail } from './thumbnail';

export const TemplatesPanel = observer(({store}) => {
    const [templates, setTemplates] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);

    const load = async (q) =>{
        const response = await fetch(`${globalStore.serverUrl}/cloudtemplates${q?.currentTarget?.value ? '?q=' + q.currentTarget.value : ''}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            }
        });

        if(response.ok){
            const data = await response.json();
            setTemplates(data);
        }


        setIsLoading(false);
    }

    globalStore.loadTemplates = load;

    const handleNew = () =>{
      if(globalStore.contentChanged && !window.confirm("All changes will be lost! Are you sure?"))return;
      store.clear();
      store.addPage();
      globalStore.template = {name: 'New Template'};
      globalStore.contentChanged = false;
    }

    const handleSelect = async(template) => {
        if(globalStore.contentChanged && !window.confirm("All changes will be lost! Are you sure?")) return;
        const req = await fetch(`${globalStore.serverUrl}/cloudtemplates/${template.id}`, {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            }
        });

        const json = await req.json();

        const pagesIds = store.toJSON().pages.map(page => page.id);
        store.deletePages(pagesIds);
        const pages = json.content.pages;
        for (const i in pages) {
            store.addPage(pages[i]);
        }
        store.selectPage(pages[0]?.id);
        globalStore.template = json;
        globalStore.contentChanged = false;
    }

    const handleDelete = async(template) => {
        if(!window.confirm("Asset will be permanently deleted. Are you sure?")) return;

        const res = await fetch(`${globalStore.serverUrl}/cloudtemplates/${template.id}`, {
            method: 'DELETE',
            headers: {
                ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            }
        });

        if(res.ok){
            await load();
        }
    }

    const checkSelected = (template) =>{
        return globalStore.template?.id === template.id;
    }

    const getTemplateName = (template) => {
        return globalStore.template?.id === template.id ? globalStore.template.name : template.name;
    }

    React.useEffect(()=>{
        load();
    },[]);

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection:'column' }}>
        <div className='flex flex-col mb-1'>
            {globalStore.userRole==='admin' && 
                <div className='flex mb-1'>
                    <Button className='w-1/2' icon='add' onClick={handleNew}>New Template</Button>
                </div>     
            }
            <InputGroup leftIcon="search" placeholder="Search..." onChange={(e) => {load(e);}} style={{marginBottom: '20px',}}/>
        </div>
        
        
        <div className='grid grid-cols-2 gap-[4px] overflow-y-auto overflow-x-hidden'>
            {templates.map((template, index) => 
                <Thumbnail key={template.id} src={template.thumbnail} name={getTemplateName(template)} selected={checkSelected(template)}
                onSelect={()=>handleSelect(template)}
                onDelete={()=>handleDelete(template)}
            />)}
        </div>
    </div>
  );
});
window.TE = {
    mount : (selector, apiKey, template) =>{
        const root = document.querySelector(selector);
        const editor = document.createElement('te-designer');
        editor.apiKey = apiKey;
        
        if(template){
            editor.template = template;
        }

        root.appendChild(editor);
    },
    popup : (apiKey, template, callback) => {
        const root = document.body;
        const editor = document.createElement('te-designer-popup');
        editor.apiKey = apiKey;
        editor.callback = callback;
        editor.template = template;

        root.appendChild(editor);
    }
}
import Designer from './designer';

const DesignerPopup = ({apiKey, template, callback}) => {    
    document.body.style.overflow = 'hidden';

    const handleSaveImage= (data) => {
        handleClose();
        if(callback){
            callback(data);
        }
    }

    const handleClose = () =>{
        const els = document.getElementsByTagName('te-designer-popup');
        for(const el of els){
            el.remove();
            
        }
        document.body.style.overflow = 'auto';
    }

    return (
        <div id='te-designer' className='absolute top-0 left-0 w-screen h-screen flex flex-col p-10 bg-black/35 z-[999999]'>
            <div className='rounded-[5px] w-full h-full overflow-hidden'>
                <Designer apiKey={apiKey} template={template} onClose={handleClose} onSaveImage={handleSaveImage} />
            </div>
        </div>
    )   
}

export default DesignerPopup;
import {useState} from 'react';
import { globalStore } from './globalStore';
import {Spinner, Button } from "@blueprintjs/core";
import { Trash, Edit } from "@blueprintjs/icons";


export const Thumbnail = ({src, name, className, selected,
    onUpdate, onDelete, onSelect, onRename,
    allowRename = true, allowDelete = true,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const handler = async (func) =>{
        if(func){
            setIsLoading(true);
            await func();
            setIsLoading(false);
        }
    }

    return (
        <div className={`${className || ''} relative`}>
            <div className={`flex flex-col border-gray-300 bg-gray-100 border group hover:bg-gray-200`}>
                <div className='relative cursor-pointer'>
                    <img src={src} alt={name} onClick={()=> handler(onSelect)} className='cursor-pointer' crossOrigin='anonymous'/>
                    {isLoading && <Spinner className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20' size={25}/>}
                </div>
                <div className='flex p-1 justify-between items-center border-t border-t-gray-300'>
                    <span className='select-none'>{name}</span>
                    <div className='flex pointer-events-none opacity-0 group-hover:opacity-100 group-hover:pointer-events-auto'>
                        {allowRename && onRename && <Edit onClick={()=>handler(onRename)} className='ml-1 opacity-50 hover:opacity-100 cursor-pointer' size={14}/>}
                        {allowDelete && onDelete && <Trash onClick={()=>handler(onDelete)} className='ml-1 opacity-50 hover:opacity-100 hover:text-red-600 cursor-pointer' size={14} />}
                    </div>
                </div>
            </div>
            {isLoading && <div className='bg-white/70 absolute w-full h-full top-0 left-0 select-none z-10'/>}
            {selected && <div className='absolute w-full h-full top-0 left-0 border-2 border-blue-500 pointer-events-none'></div>}
            
        </div>
    )
}
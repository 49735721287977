import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@blueprintjs/core';
import { getImageSize } from 'polotno/utils/image';
import { globalStore } from './globalStore';
import MdPhoto from '@meronex/icons/md/MdPhoto';
import { SectionTab } from 'polotno/side-panel';
import { Thumbnail } from './thumbnail';
import { InputGroup } from '@blueprintjs/core';

export const Panel = observer(({ store }) => {
  const [images, setImages] = React.useState([]);
  const [isUploading, setUploading] = React.useState(false);
  const [currentFolder, setCurrentFolder] = React.useState('');
  const [currentFolderName, setCurrentFolderName] = React.useState('');
  const [parentFolder, setParentFolder] = React.useState('');
  const [isCreatingFolder, setCreatingFolder] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);

  const load = async (f, q) => {
    const queryParams = new URLSearchParams();
    if (q) {
        queryParams.append('q', q.currentTarget.value);
    }
    queryParams.append('type', 'non-font');

    const response = await fetch(`${globalStore.serverUrl}/cloudassets/${f || ''}?${queryParams.toString()}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            ...(globalStore.apiKey ? { "x-api-key": globalStore.apiKey } : {}),
        }
    });

        if(response.ok){
            const data = await response.json();
            setCurrentFolder(data.current_folder);
            setParentFolder(data.parent_folder);
            setCurrentFolderName(data.current_folder_name);
            setImages(data.assets);
        }

        setIsLoading(false);
    };

    const uploadAsset = async(file) =>{
        const formData = new FormData();
        formData.append('file', file);
        await fetch(`${globalStore.serverUrl}/cloudassets/image/${currentFolder || ''}`, {
            method: 'POST',
            headers: {
                ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            },
            body: formData,
        });
    }

    const handleFileInput = async (e) => {
        const { target } = e;
        setUploading(true);
        for (const file of target.files) {
            await uploadAsset(file);
        }
        await load(currentFolder);
        setUploading(false);
        target.value = null;
    };

    const handleCreateFolder = async (e) => {
        const folderName = prompt("Create Folder", 'New Folder');
        if(folderName && folderName !== ''){
            setCreatingFolder(true);
            const params = {
                name : folderName,
                type: 'folder',
                ...(currentFolder ? {folder : currentFolder}:{})
            }
            
            await fetch(`${globalStore.serverUrl}/cloudassets/folder/${currentFolder || ''}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
                body: JSON.stringify(params),
            });

            await load(currentFolder);
            setCreatingFolder(false);
        }
        
    }

    const handleRename = async (asset) => {
        const newName = prompt("Rename Asset:", asset.name);
        if(newName && newName !== '' && newName !== asset.name){
            const res = await fetch(`${globalStore.serverUrl}/cloudassets/${asset._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
                },
                body : JSON.stringify({
                    name: newName
                })
            });

            if(res.ok){
                await load(currentFolder);
            }
        }
    }

    const handleSelect = async(asset) => {
        if(asset.type==='image') await selectImage(asset);
        else if(asset.type==='folder') await selectFolder(asset);
    }

    const selectFolder = async(asset) =>{
        await load(`${currentFolder}/${asset._id}`);
    }

    const selectImage = async (asset) => {
        const {url} = asset;
        debugger;
        const element = store.selectedElements[0];
        let { width, height } = await getImageSize(url);
        const isSVG = url.indexOf('svg+xml') >= 0 || url.indexOf('.svg') >= 0;

        const type = isSVG ? 'svg' : 'image';

        if (
        element &&
        element.type === 'svg' &&
        !element.locked &&
        type === 'image'
        ) {
        element.set({ maskSrc: url });
        return;
        }

        if (
        element &&
        element.type === 'image' &&
        !element.locked &&
        type === 'image'
        ) {
        element.set({ src: url });
        return;
        }

        const scale = Math.min(store.width / width, store.height / height, 1);
        width = width * scale;
        height = height * scale;

        const x = (store.width / 2) - width / 2;
        const y = (store.height / 2) - height / 2;

        store.activePage?.addElement({
        type,
        src: url,
        x,
        y,
        width,
        height,
        name : asset.name
        });
    }

    const handleBack = async() => {
        await load(parentFolder);
    }

  const handleDelete = async(asset) => {
    if(!window.confirm("Asset will be permanently deleted. Are you sure?")) return;

    const res = await fetch(`${globalStore.serverUrl}/cloudassets/${asset._id}`, {
      method: 'DELETE',
      headers: {
        ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
      }
    });

    if(res.ok){
        await load(currentFolder);
    }
  }

  React.useEffect(() => {
    load();
  }, []);

    return (
        <div className='flex flex-col h-full'>
            <div className='flex flex-col mb-1'>
                <div className='flex mb-1'>
                    <label htmlFor="input-file" className='w-1/2 mb-0 font-normal flex-initial'>
                    <Button icon="media" style={{ width: '100%' }} onClick={() => {document.querySelector('#input-file')?.click();}} loading={isUploading}>New Asset</Button>
                    <input type="file" id="input-file" style={{ display: 'none' }} onChange={handleFileInput} multiple/>
                    </label>
                    <Button icon="folder-new" className='w-1/2' onClick={handleCreateFolder} loading={isCreatingFolder}>New Folder</Button>
                </div>
                <InputGroup leftIcon="search" placeholder="Search..." onChange={(e) => {load(currentFolder, e);}}/>
            </div>
            <div className='flex items-center mb-1'>
                {currentFolder && <Button icon="arrow-left" className='mr-1'  onClick={handleBack} loading={isCreatingFolder}></Button>}
                <span className='text-xl font-bold mb-1'>{currentFolderName || 'Root'}</span>
            </div>
            <div className='grid grid-cols-2 gap-[4px] overflow-y-auto overflow-x-hidden'>
                {images.map((image, index) => 
                <Thumbnail key={image._id} src={image.type==='image' ? image.url : 'https://d2f9eheulyqk7v.cloudfront.net/internal/folder.svg'} name={image.name}
                onSelect={()=>handleSelect(image)}
                allowRename={globalStore.userRole==='admin'}
                allowDelete={globalStore.userRole==='admin'}
                onRename={()=> handleRename(image)} 
                onDelete={()=> handleDelete(image)}
                />)}
            </div>
        </div>
    );
});

export const AssetsSection = {
    name: 'assets',
    Tab: (props) => (
      <SectionTab name="Assets" {...props} >
        <span className='bp5-icon bp5-icon-fullscreen'>
            <MdPhoto height="16px" style={{height: '16px', width: '16px'}}/>
        </span>
      </SectionTab>
    ),
    Panel: Panel,
  };

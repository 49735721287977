import React from "react";
import * as ReactDOM from "react-dom/client";
import reactToWebcomponent from "react-to-webcomponent";
import PropTypes from "prop-types";
import Designer from "./designer";
import DesignerPopup from "./popup";
import './functions.js';
import './index.css';

Designer.propTypes = {
  apiKey        : PropTypes.string,
  template      : PropTypes.string,
};

DesignerPopup.propTypes = {
    apiKey        : PropTypes.string,
    template      : PropTypes.string,
    callback      : PropTypes.func  
};

customElements.define("te-designer", reactToWebcomponent(Designer, React, ReactDOM));
customElements.define("te-designer-popup", reactToWebcomponent(DesignerPopup, React, ReactDOM));
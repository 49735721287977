import React, { useState } from 'react';
import { Button, Popover, Position, Card, Elevation, FormGroup, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import { globalStore } from './globalStore';
import '../styles/downloadPopOver.css';

const typesItems = [{ label: 'PNG', value: 'png' }];

const TemplateDownloadButton = observer(({ store }) => {
    const [selectedPages, setSelectedPages] = useState([]);
    const [downloadPopoverOpen, setDownloadPopoverOpen] = useState(false);
    const [pagesPopoverOpen, setPagesPopoverOpen] = useState(false);
    const [pageOptions, setPageOptions] = useState([]);

    const handleSelectPage = (item) => {
        if (item.value === 'all') {
            handleSelectAll();
        } else {
            setSelectedPages([item]);
        }
    };

    const openPopover = () => {
        setDownloadPopoverOpen(!downloadPopoverOpen);
        handleSelectAll();
        setPageOptions([
            { label: "All", value: 'all' },
            ...store.pages.map((page, index) => ({ label: `Page ${index + 1}`, value: page.id }))
        ]);
    }

    const handleSelectAll = () => {
        setSelectedPages(store.pages.map((page, index) => ({label: `Page ${index + 1}`, value: page.id})));
    };

    const handleDownload = async () => {
        const currentDateTime = format(new Date(), 'dd-MM-yyyy HH:mm');
        const templateName = globalStore.template.name;

        const pagesToDownload = selectedPages;

        for (const page of pagesToDownload) {
            const fileName = `${templateName}_${currentDateTime}.png`;
            store.saveAsImage({ pageId: page.value, fileName, mimeType: 'image/png' });
        }
        setDownloadPopoverOpen(false);
    };

    const renderSelectPageItem = (item, { handleClick, modifiers }) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                key={item.value}
                onClick={handleClick}
                text={item.label}
            />
        );
    };

    const manageDownloadPopover = (interactionState) => {
        if(!pagesPopoverOpen) {
            setDownloadPopoverOpen(interactionState);
        }
    }

    const renderDownloadMenu = () => (
        <Card elevation={Elevation.TWO} className="download-menu">
            <FormGroup label="File Type" labelFor="file-type">
                <Button className='bp5-button bp5-fill bp5-align-left' text={typesItems[0]?.label} />
            </FormGroup>
            <FormGroup label="Select Pages" labelFor="select-pages">
                {store.pages?.length > 1 ? 
                    <Select
                        filterable={false}
                        popoverProps={{
                            minimal: true,
                            matchTargetWidth: true,
                            onInteraction: (state) => setPagesPopoverOpen(state),
                            isOpen: pagesPopoverOpen
                        }}
                        items={pageOptions}
                        itemRenderer={renderSelectPageItem}
                        onItemSelect={handleSelectPage}
                        multiSelect
                    >
                        <Button
                            className='bp5-button bp5-fill bp5-align-left'
                            text={selectedPages.length > 1 ? `All pages (${store.pages?.length})` : selectedPages[0]?.label}
                            rightIcon="caret-down"
                        />
                    </Select>
                    :
                    <Button
                        className='bp5-button bp5-fill bp5-align-left'
                        text={selectedPages[0]?.label}
                    />
                }
            </FormGroup>
            <Button intent="primary" className="download-button" onClick={handleDownload}>Download</Button>
        </Card>
    );

    return (
        <Popover
            content={renderDownloadMenu()}
            position={Position.BOTTOM}
            isOpen={downloadPopoverOpen}
            onInteraction={(interactionState) => manageDownloadPopover(interactionState)}
        >
            <Button intent='Primary' minimal className="bp3-intent-primary" icon="import" onClick={openPopover}>
                Download
            </Button>
        </Popover>
    );
});

export default TemplateDownloadButton;

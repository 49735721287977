import {useState} from 'react';
import { observer } from 'mobx-react-lite';
import {Edit, Duplicate } from "@blueprintjs/icons";
import { Button } from '@blueprintjs/core';
import { globalStore } from './globalStore';

export const TemplateSaveButton = observer(({store, onClick}) => {
    const [isLoading, setIsLoading] = useState(false);

    const saveTemplate = async () =>{    
        setIsLoading(true);

        const content = store.toJSON();
        const data = {
            ...globalStore.template,
            content : {...content, fonts: []}
        };
        
        const res = await fetch(`${globalStore.serverUrl}/cloudtemplates`, {
            method: "POST",
            headers: {
            'Content-Type': 'application/json',
            ...(globalStore.apiKey ? {"x-api-key": globalStore.apiKey} : {}),
            },
            body : JSON.stringify(data)
        });

        if(res.ok){
            globalStore.contentChanged = false;
            await globalStore.loadTemplates();
        }

        setIsLoading(false);
    };
    return(
        <Button intent='Primary' disabled={!globalStore.contentChanged} loading={isLoading}  onClick={saveTemplate}>&emsp; Save &emsp;</Button>
    );
});